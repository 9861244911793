import { Component, OnDestroy, OnInit } from '@angular/core'
import { SupportedLanguages } from '@emma/type-definitions/types'
import { EmmaService } from '@emmaServices/emma.service'
import { TranslateService } from '@ngx-translate/core'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { AppService } from 'src/app/app.service'

@Component({
  selector: 'ems-energy-management',
  templateUrl: './energy-management.component.html',
  styleUrls: ['./energy-management.component.scss'],
})
export class EnergyManagementComponent implements OnInit, OnDestroy {
  public showBrowserLanguageOverylay = false
  public browserLanguage: SupportedLanguages = 'en'

  public languageNames: Record<SupportedLanguages, string> = {
    en: 'English',
    de: 'German',
    it: 'Italian',
    es: 'Spanish',
    fr: 'French',
  }

  private supportedLanguages: string[] = ['en', 'de', 'it', 'es', 'fr']

  constructor(
    private app: AppService,
    private emma: EmmaService,
    public ui: UiService,
    public proficloud: ProficloudService,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    if (this.app.environment.frontend === 'production') {
      return
    }

    // Set back to regular locale
    this.translate.use('en-GB')
  }

  ngOnInit(): void {
    if (this.app.environment.frontend === 'production') {
      return
    }

    // Check local storage
    const confirmation = this.emma.getFromLocalStorage('ems-browser-language')
    // Previously chose to use browser language
    if (confirmation === 'true') {
      this.emma.useLanguage()
      return
    }

    // Previously chose to not use browser language
    if (confirmation === 'false') {
      return
    }

    // Not chosen yet
    const generalLanguage = navigator.language.slice(0, 2)
    if (this.supportedLanguages.indexOf(generalLanguage)) {
      this.browserLanguage = generalLanguage as SupportedLanguages
      this.showBrowserLanguageOverylay = true
    }
  }

  public ignore() {
    this.emma.setToLocalStorage('ems-browser-language', 'false')
    this.showBrowserLanguageOverylay = false
  }

  public useBrowserLanguage() {
    this.emma.useLanguage()

    // Persist to localstorage
    this.emma.setToLocalStorage('ems-browser-language', 'true')

    this.showBrowserLanguageOverylay = false
  }
}
