<div class="container">
  <router-outlet></router-outlet>
</div>

<!-- Browser language overlay -->
@if (showBrowserLanguageOverylay) {
  <pc-overlay [config]="{ title: 'Automatic Language Detection' }" (closeClick)="showBrowserLanguageOverylay = false">
    <ng-container body>
      <div>
        We detected that your browser language is {{ languageNames[browserLanguage] }}. Would you like to automatically translate our service into
        {{ languageNames[browserLanguage] }}? You can turn off automatic translation using the toggle in the settings tab on the left side panel.
      </div>
    </ng-container>
    <ng-container buttons>
      <flex-row>
        <div-grow>
          <pc-button variant="secondary" size="small" [text]="'SHARED.IGNORE' | translate" (btnClick)="ignore()"></pc-button>
        </div-grow>
        <div style="margin-left: 16px">
          <pc-button size="small" [text]="'SHARED.TRANSLATE' | translate" variant="primary" (btnClick)="useBrowserLanguage()"></pc-button>
        </div>
      </flex-row>
    </ng-container>
  </pc-overlay>
}
